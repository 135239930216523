import type { Application } from '~/generated/api-clients-generated'
import type { ComponentContent } from '~/types/cms'

export type ApplicationStatusType =
  | 'in-progress'
  | 'received'
  | 'pending'
  | 'approved'
  | 'denied'
  | 'invalid'
  | 'expired'
  | 'termed'

export type ApplicationStatus = {
  id: string
  display: string
  type: ApplicationStatusType
}

export class ApplicationStatuses {
  static cmsContent: ComponentContent | null = null

  static async initialize() {
    const { getComponentContent } = Cms.use()
    const { content } = await getComponentContent('ApplicationStatusDisplay')

    this.cmsContent = content.value
  }

  static use() {
    const inProgressStatuses = ['started']
    const receivedStatuses = ['submitted', 'not_accepted', 'delivery_failed']
    const pendingStatuses = [
      'sent_to_carrier',
      'pending',
      'carrier_confirmed_receipt',
      'pending_declined'
    ]
    const approvedStatuses = ['approved']
    const deniedStatuses = ['declined']
    const hiddenStatuses = ['inactive_not_interested', 'inactive_backoffice', 'duplicate']
    const expiredStatuses = ['inactive_expired']
    const termedStatuses = ['termed', 'rapid_disenrollment']
    const invalidStatuses = [
      'ineligible',
      'withdrawn_proactive',
      'withdrawn_passive',
      'cancelled_never_effective',
      'cancelled_proactive',
      'cancelled_non_payment',
      'cancelled_deceased'
    ]

    const isInProgress = (app: Application) =>
      app?.status && inProgressStatuses.includes(app.status)
    const isReceived = (app: Application) => app?.status && receivedStatuses.includes(app.status)

    const isPending = (app: Application) => app?.status && pendingStatuses.includes(app.status)

    const isApproved = (app: Application) => app?.status && approvedStatuses.includes(app.status)

    const isDenied = (app: Application) => app?.status && deniedStatuses.includes(app.status)

    const isHidden = (app: Application) => app?.status && hiddenStatuses.includes(app.status)

    const isExpired = (app: Application) => app?.status && expiredStatuses.includes(app.status)

    const isTermed = (app: Application) => app?.status && termedStatuses.includes(app.status)

    const mapStatusType = (app: Application): ApplicationStatusType => {
      if (!app?.status) {
        console.error('Failed to determine status group for app: ', app)
        return 'invalid'
      }

      if (isInProgress(app)) return 'in-progress'
      if (isReceived(app)) return 'received'
      if (isPending(app)) return 'pending'
      if (isApproved(app)) return 'approved'
      if (isDenied(app)) return 'denied'
      if (isExpired(app)) return 'expired'
      if (isTermed(app)) return 'termed'

      return 'invalid'
    }

    const getStatus = (app: Application): ApplicationStatus | null => {
      if (!this.cmsContent) throw new Error('CMS Content must be initialized')
      if (app?.status && this.cmsContent?.ext?.hasOwnProperty(app.status)) {
        return {
          id: app.status,
          display: this.cmsContent.ext[app.status],
          type: mapStatusType(app)
        }
      }

      console.error('Failed to determine status for application: ', app)
      return null
    }

    return {
      isInProgress,
      isReceived,
      isPending,
      isApproved,
      isDenied,
      isHidden,
      mapStatusType,
      getStatus
    }
  }
}
